import React from 'react';

// Root Include

//Special
const PageComingSoon = React.lazy(() => import('./pages/Pages/Special/PageComingSoon'));


const routes = [
    //Index Main
    { path: '/index', component: PageComingSoon, isWithoutLayout : true },

    //Root
    { path: '/', component: PageComingSoon, isWithoutLayout : true }

];

export default routes;